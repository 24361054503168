import { AsyncPipe, CommonModule } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, Router, RouterOutlet } from '@angular/router';
import { AuthService, EmitService } from '@core';
import { LayoutService } from '@core/services/layout.service';
import { LayoutConfig, User } from '@models';
import { BackComponent } from '@shared/component/back/back.component';
import { CusNavbarComponent } from '@shared/component/cus-navbar/cus-navbar.component';
import { CusSidenavComponent } from '@shared/component/cus-sidenav/cus-sidenav.component';
import { EncapsulatedBannerComponent } from '@shared/component/encapsulated-banner/encapsulated-banner.component';
import { FooterComponent } from '@shared/component/footer/footer.component';
import { SpinnerComponent } from '@shared/component/spinner/spinner.component';
import { TopGuideBannerComponent } from '@shared/component/top-guide-banner/top-guide-banner.component';
import { plainToClass } from 'class-transformer';
import { Observable, Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { NotificationsComponent } from '../notifications/notifications.component';
import { PanelSidenavComponent } from '../panel-sidenav/panel-sidenav.component';
import { FeedbackComponent } from '../feedback/feedback.component';

@Component({
  selector: 'app-cus-main-layout',
  templateUrl: './cus-main-layout.component.html',
  styleUrls: ['./cus-main-layout.component.scss'],
  standalone: true,
  imports: [
    TopGuideBannerComponent,
    CusNavbarComponent,
    CusSidenavComponent,
    EncapsulatedBannerComponent,
    BackComponent,
    RouterOutlet,
    FooterComponent,
    PanelSidenavComponent,
    NotificationsComponent,
    FeedbackComponent,
    SpinnerComponent,
    AsyncPipe,
    CommonModule,
  ],
})
export class CusMainLayoutComponent implements OnInit, OnDestroy {
  layoutConfig: LayoutConfig;

  user$: Observable<User> = this.authService.user$;
  userInfo: User;
  private readonly subs: Subscription[] = [];

  backText = '';
  backRoute = '/';

  constructor(
    public layoutService: LayoutService,
    public emitService: EmitService,
    public readonly authService: AuthService,
    public router: Router,
  ) {
    this.layoutConfig = layoutService.layoutConfig;
  }

  ngOnInit() {
    this.subs.push(
      this.user$.subscribe((user) => {
        this.userInfo = plainToClass(User, user);
      }),
    );

    this.getBackInfo();
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe(() => {
        this.getBackInfo();
      });
  }

  getBackInfo() {
    if (
      this.layoutService.currentUrl.includes(
        'overview/all-task-list/task-detail',
      )
    ) {
      this.backText = 'Task List';
      this.backRoute = this.layoutService.currentUrl.includes('/bookkeeper')
        ? `/bookkeeper/${this.layoutConfig.tenantKey}/overview/all-task-list`
        : '/overview/all-task-list';
    } else if (
      this.layoutService.currentUrl.includes('overview/all-task-list') ||
      this.layoutService.currentUrl.includes('overview/activity-log')
    ) {
      this.backText = 'Overview';
      this.backRoute = this.layoutService.currentUrl.includes('/bookkeeper')
        ? `/bookkeeper/${this.layoutConfig.tenantKey}/overview`
        : '/overview';
    } else if (
      this.layoutService.currentUrl.includes(
        'task-list/customers-task-list/task-detail',
      ) ||
      this.layoutService.currentUrl.includes(
        'task-list/customers-task-list/all-task-list',
      )
    ) {
      this.backText = 'My Tasks';
      this.backRoute = this.layoutService.currentUrl.includes('/bookkeeper')
        ? `/bookkeeper/${this.layoutConfig.tenantKey}/task-list/customers-task-list`
        : '/task-list/customers-task-list';
    }
  }

  ngOnDestroy() {
    this.subs.forEach((sub) => sub.unsubscribe());
  }
}
