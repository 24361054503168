import { Type } from 'class-transformer';
import { Unescape } from '../decorators';
import { Employee } from './employee.model';
import { Permission } from './permission.model';
import { Tenant } from './tenant.model';
import { UserType } from './user-type.model';
import { UserType as USER_TYPE } from '@core';
import * as changeCase from 'change-case';

export class User {
  userKey?: number;

  email: string;

  jwt?: string;

  phone: string;

  countryCode?: string;

  @Unescape()
  firstNme: string;

  @Unescape()
  lastNme: string;

  password?: string;

  bio?: string;

  avatar?: string;

  appointmentLink?: string;

  specialRole?: boolean;

  @Type(() => Tenant)
  tenant?: Tenant;

  @Type(() => Tenant)
  originalPriTenant?: Tenant;

  @Type(() => Permission)
  permissions?: Permission[];

  @Type(() => UserType)
  userType: UserType;

  @Type(() => Employee)
  employee?: Employee;

  isCurrent?: boolean;

  isPrimary?: boolean;

  updatedAt?: string;

  userStatus: UserStatus;

  moreAction?: boolean;

  activated?: boolean;

  isDeletable?: boolean;

  businessFunction?: BusinessFunction;

  theme?: string;

  iamUserType?: string;

  get isSuperAdmin() {
    return this.userType?.nme === USER_TYPE.SUPER_ADMIN;
  }

  get isBKAdmin() {
    return this.userType?.nme === USER_TYPE.BOOKKEEPER_ADMIN;
  }

  get isBookKeeper() {
    return this.userType?.nme === USER_TYPE.BOOKKEEPER;
  }

  get isBookKeeperUser() {
    return this.isSuperAdmin || this.isBKAdmin || this.isBookKeeper;
  }

  get isAdmin() {
    return this.userType?.nme === USER_TYPE.ADMIN;
  }

  get isMember() {
    return this.userType?.nme === USER_TYPE.MEMBER;
  }

  get isContributor() {
    return this.userType?.nme === USER_TYPE.CONTRIBUTOR;
  }

  get isCustomerUser() {
    return this.isAdmin || this.isMember || this.isContributor;
  }

  get role() {
    return changeCase['title'](this.userType?.nme || '');
  }

  get isRegistered() {
    if (this.isFederated) {
      return true;
    }
    return this.userStatus?.nme === 'Active';
  }

  get isNew() {
    return this.userStatus?.nme === 'New';
  }

  get isInvited() {
    return this.userStatus?.nme === 'Invited';
  }

  get hasName() {
    return this.firstNme && this.lastNme;
  }

  get name() {
    return this.hasName ? `${this.firstNme} ${this.lastNme}` : '';
  }

  get abbr() {
    return this.hasName
      ? `${this.firstNme[0]}${this.lastNme[0]}`.toUpperCase()
      : '';
  }

  get info() {
    return this.hasName ? `${this.name} (${this.email})` : '';
  }

  get regStatus() {
    if (this.isFederated) {
      return USER_STATUS.Active;
    }
    return USER_STATUS[this.userStatus?.nme];
  }

  get isFederated() {
    return this.iamUserType === 'federated';
  }
}

export interface UserStatus {
  userStatusKey: number;
  nme: string;
}

export interface BusinessFunction {
  businessFunctionKey: number;
  name: number;
}

const USER_STATUS = {
  Invited: 'Invite Sent',
  Active: 'Registered',
  New: 'Not Invited',
  Disabled: 'Disabled',
};
