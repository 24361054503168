import { Component, OnDestroy } from '@angular/core';
import { NotificationService } from '@core/services';
import { NgFor, AsyncPipe } from '@angular/common';
import {
  NotificationModule,
  NotificationService as appkitNotificationService,
} from '@appkit4/angular-components/notification';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-encapsulated-banner',
  templateUrl: './encapsulated-banner.component.html',
  styleUrls: ['./encapsulated-banner.component.scss'],
  standalone: true,
  imports: [NgFor, AsyncPipe, NotificationModule],
})
export class EncapsulatedBannerComponent implements OnDestroy {
  status: 'default' | 'success' | 'warning' | 'error' | string = 'default';

  subscription: Subscription;
  constructor(
    public notificationService: NotificationService,
    protected _notificationSvc: appkitNotificationService,
  ) {
    this.subscription = this.notificationService.notifications$.subscribe(
      (next: { type: BannerStatus; title: string; message: string }) => {
        this.openNotification(next.type, next.title, next.message);
      },
    );
  }

  ngOnDestroy() {
    if (this.subscription) this.subscription.unsubscribe();
  }

  openNotification(type = BannerStatus.success, title, message) {
    this.status = type;
    let duration = 0;
    let icon = 'icon-success-fill';
    switch (type) {
      case BannerStatus.warning:
        icon = 'icon-warning-fill';
        break;
      case BannerStatus.wait:
        duration = 5000;
        icon = 'icon-time-fill';
        break;
      case BannerStatus.error:
        icon = 'icon-error-fill';
        break;
      case BannerStatus.info:
        duration = 5000;
        icon = 'icon-information-fill';
        break;
      default:
        duration = 5000;
        break;
    }
    this.createNotification(title, message, duration, icon);
  }

  createNotification(
    title: string,
    message = '',
    duration = 0,
    icon = 'icon-success-fill',
    id = 'notification-1',
  ): void {
    const hyperLink = '';
    const hyperLinkHref = '';
    this._notificationSvc.show(title, message, hyperLink, hyperLinkHref, {
      duration: duration,
      id: id,
      clickToClose: false,
      showClose: true,
      icon: icon,
    });
    setTimeout(() => {
      const domList = document.querySelectorAll('#notification-1');
      domList[domList.length - 1]?.setAttribute('role', 'alert');
    }, 100);
  }
}

export enum BannerStatus {
  warning = 'warning',
  error = 'error',
  info = 'information',
  success = 'success',
  wait = 'wait',
}
