import { Injectable } from '@angular/core';
import { BannerStatus } from '@shared/component/encapsulated-banner/encapsulated-banner.component';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  notifications$ = new Subject<{type: BannerStatus, title: string, message: string}>();

  constructor() {}
}
