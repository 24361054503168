<div class="feedback-detail-panel" appClickOutside (clickOutside)="onClickedOutside($event)"
  [clickOutsideEnabled]="clickOutsideEnabled">
  <ap-drawer [visible]="isOpened" [placement]="'right'" [mask]="false" [initialFocusIndex]="0"
    [styleClass]="'feedback-detail-container'" [drawerTitle]="'Feedback details'"
    (onClose)="closeFeedbackDetailPanel()">
    <div class="read-split ap-mb-spacing-5"></div>
    <div class="ap-mb-spacing-7">
      <h2 class="ap-typography-heading-s ap-mb-spacing-5 ap-font-weight-3">User</h2>
      <div>
        <span class="ap-font-weight-2">User Name: </span>
        <span class="break-word">{{feedbackInfo?.user?.firstNme}} {{feedbackInfo?.user?.lastNme}}</span>
      </div>
      <div class="ap-mt-spacing-3">
        <span class="ap-font-weight-2">Email Address: </span>
        <span class="break-word">{{feedbackInfo?.user?.email}}</span>
      </div>
    </div>
    <div class="ap-mb-spacing-7">
      <h2 class="ap-typography-heading-s ap-mb-spacing-5 ap-font-weight-3">Feedback</h2>
      <div>
        <span class="ap-font-weight-2">Type: </span>
        <span>{{feedbackInfo?.feedbackType?.displayName}}</span>
      </div>
      <div class="ap-mt-spacing-3">
        <span class="ap-font-weight-2">Description: </span>
        <span class="break-word" [attr.aria-label]="feedbackInfo?.content" [innerHTML]="feedbackInfo?.content"></span>
      </div>
    </div>
    <div>
      <h2 class="ap-typography-heading-s ap-mb-spacing-5 ap-font-weight-3">Attachments</h2>
      <div class="flex-normal flex-direction-column flex-gap-3">
        <ng-container *ngFor="let item of feedbackInfo?.feedbackAttachments">
          <div class="flex-normal ap-typography-body flex-gap-2">
            <span class="Appkit4-icon icon-document-text-outline ap-ml-spacing-2"></span>
            <div class="preview-file-name ap-text-primary-blue-04 cursor-pointer text-ellipsis"
              [attr.aria-label]="item?.fileName ?? item?.name" tabindex="0" role="link"
              (keyup.enter)="downloadAttachment(item)" (click)="downloadAttachment(item)">
              {{item?.fileName ?? item?.name}}
            </div>
          </div>
        </ng-container>
      </div>
    </div>
  </ap-drawer>
</div>
