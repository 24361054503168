import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { NgIf, NgFor } from '@angular/common';
import { FileuploaderModule } from '@appkit4/angular-components/fileupload';
import { TooltipModule } from '@appkit4/angular-components/tooltip';

@Component({
  selector: 'app-upload-file',
  templateUrl: './upload-file.component.html',
  styleUrls: ['./upload-file.component.scss'],
  standalone: true,
  imports: [FileuploaderModule, NgIf, NgFor, TooltipModule],
})
export class UploadFileComponent implements OnChanges {
  @Input() uploadTitle = 'Upload your files';
  @Input() isEditMode = false;
  @Input() hideFiles = false;
  @Input() disabled = false;
  @Input() fileKey = 'attachmentKey';
  @Input() filesData: UploadFileTransfer;
  @Input() uploadInstruction =
    'Acceptable formats: XLS, XLSX, CSV, PDF, ZIP. The max file size is 10MB.';
  @Input() config = {
    multiple: true,
    type: 'inline',
    trigger: false,
  };
  @Input() customUploader = {
    maxFileSize: 10 * 1024 * 1024,
    autoUpload: false,
    maxFileLimit: null,
  };
  @Input() fileType = ['XLS', 'XLSX', 'CSV', 'PDF', 'ZIP'];
  @Input() hiddenTitle = false;
  @Output() filesDataChange = new EventEmitter<UploadFileTransfer>();
  totalFileSize: number = 0;
  isTotalFileSizeOverSize = false;
  isTotalFileLimitOver = false;
  isFileTypeNotAllowed = false;
  singleFailTime = null;
  totalFailTime = null;
  singleFailList = [];
  totalFailList = [];
  readonly UPLOAD_INTERVAL = 1000;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.disabled?.previousValue !== changes?.disabled?.currentValue) {
      if (changes?.disabled?.currentValue) {
        this.disableUpload();
      } else {
        this.enableUpload();
      }
    }
  }

  onDeleteFile(fileInfo: any) {
    if (fileInfo[this.fileKey]) {
      this.filesData.atchKeysToBeRmvd.push(fileInfo[this.fileKey]);
    }
    this.filesData.fileList = this.filesData.fileList.filter(
      (_file) =>
        _file.name !== fileInfo?.name || _file.fileName !== fileInfo.fileName,
    );
    if (this.isEditMode) {
      this.filesData.newAtchs = this.filesData.newAtchs.filter(
        (_file) => _file.name !== fileInfo?.name,
      );
    }
    if (fileInfo?.size) {
      this.totalFileSize -= fileInfo?.size;
      this.resetErrorInfo();
      this.isTotalFileLimitOver = false;
    }
    this.enableUpload();
    this.emitFilesDataChange();
  }

  onValidatedFail(fileInfo: any) {
    const currentTime = new Date().getTime();
    this.revalidateError();
    if (
      this.singleFailTime &&
      currentTime - this.singleFailTime < this.UPLOAD_INTERVAL
    ) {
      this.singleFailList.push(fileInfo);
    } else {
      this.singleFailList = [];
      this.singleFailList.push(fileInfo);
    }
    this.singleFailTime = new Date().getTime();
    if (fileInfo.errorType === 'size') {
      this.isTotalFileSizeOverSize = true;
    } else if (fileInfo.errorType === 'Type') {
      this.isFileTypeNotAllowed = true;
    }
  }

  onAddFile(fileInfo: any) {
    if (this.customUploader.maxFileLimit) {
      if (this.filesData.fileList.length >= this.customUploader.maxFileLimit) {
        this.disableUpload();
        this.isTotalFileLimitOver = true;
        return;
      } else {
        this.enableUpload();
        this.isTotalFileLimitOver = false;
      }
    }
    if (
      this.filesData.fileList.some(
        (_file) =>
          _file?.name === fileInfo.file?.name ||
          _file.fileName === fileInfo.file?.name,
      ) ||
      !this.checkFileType(fileInfo)
    ) {
      return;
    }
    if (fileInfo?.file?.size) {
      this.totalFileSize += fileInfo?.file?.size;
    }
    const currentTime = new Date().getTime();
    this.revalidateError();
    if (this.totalFileSize > this.customUploader.maxFileSize) {
      this.totalFileSize -= fileInfo?.file?.size;
      this.isTotalFileSizeOverSize = true;
      if (
        this.totalFailTime &&
        currentTime - this.totalFailTime < this.UPLOAD_INTERVAL
      ) {
        this.totalFailList.push(fileInfo);
      } else {
        this.totalFailList = [];
        this.totalFailList.push(fileInfo);
      }
      this.totalFailTime = new Date().getTime();
      return;
    }
    if (currentTime - this.singleFailTime > this.UPLOAD_INTERVAL) {
      this.singleFailList = [];
      this.singleFailTime = null;
    }
    if (this.isEditMode) {
      fileInfo?.file?.rawFile &&
        this.filesData.newAtchs.push(fileInfo?.file?.rawFile);
    }
    fileInfo?.file?.rawFile &&
      this.filesData.fileList.push(fileInfo?.file?.rawFile);
    if (this.customUploader.maxFileLimit) {
      if (this.filesData.fileList.length >= this.customUploader.maxFileLimit) {
        this.disableUpload();
      }
    }
    this.emitFilesDataChange();
  }

  deleteAll() {
    if (this.isEditMode) {
      this.filesData.atchKeysToBeRmvd.push(
        ...this.filesData.fileList
          .filter((file) => file[this.fileKey])
          .map((file) => file[this.fileKey]),
      );
    }
    this.totalFileSize -= this.filesData.fileList
      .filter((fileInfo) => fileInfo.size)
      .reduce((totalSize, fileInfo) => {
        return totalSize + fileInfo.size;
      }, 0);
    this.isTotalFileSizeOverSize = false;
    this.isFileTypeNotAllowed = false;
    this.filesData.fileList = [];
    this.filesData.newAtchs = [];
    this.resetErrorInfo();
    this.isTotalFileLimitOver = false;
    this.enableUpload();
    this.emitFilesDataChange();
  }

  checkFileType(fileObj): boolean {
    if (this.fileType.length) {
      let suffixIndex = fileObj.file.name.lastIndexOf('.');
      let suffix = fileObj.file.name.substring(suffixIndex + 1);
      return this.fileType.some(
        (type: string) => type.toLowerCase() === suffix.toLowerCase(),
      );
    }
    return false;
  }

  resetErrorInfo() {
    this.isTotalFileSizeOverSize = false;
    this.isFileTypeNotAllowed = false;
    this.singleFailList = [];
    this.totalFailList = [];
    this.singleFailTime = null;
    this.totalFailTime = null;
  }

  revalidateError() {
    const currentTime = new Date().getTime();
    if (
      (!this.singleFailTime && !this.totalFailTime) ||
      (this.singleFailTime &&
        currentTime - this.singleFailTime > this.UPLOAD_INTERVAL) ||
      (this.totalFailTime &&
        currentTime - this.totalFailTime > this.UPLOAD_INTERVAL)
    ) {
      this.resetErrorInfo();
    }
  }

  emitFilesDataChange() {
    this.filesDataChange.emit({
      fileList: this.filesData.fileList,
      newAtchs: this.filesData.newAtchs,
      atchKeysToBeRmvd: this.filesData.atchKeysToBeRmvd,
    });
  }

  enableUpload() {
    const uploadButton = document.getElementsByClassName(
      'ap-fileupload-drop-btn',
    )[0] as any;
    uploadButton.disabled = false;
    uploadButton.style.cursor = 'pointer';
    const uploadText = document.getElementsByClassName(
      'ap-fileupload-drop-browse-span',
    )[0];
    uploadText.classList.add('ap-link');
    uploadText.classList.remove('app-text-disabled');
  }

  disableUpload() {
    const uploadButton = document.getElementsByClassName(
      'ap-fileupload-drop-btn',
    )[0] as any;
    uploadButton.disabled = true;
    uploadButton.style.cursor = 'not-allowed';
    const uploadText = document.getElementsByClassName(
      'ap-fileupload-drop-browse-span',
    )[0];
    uploadText.classList.remove('ap-link');
    uploadText.classList.add('app-text-disabled');
  }
}

export interface UploadFileTransfer {
  fileList?: any[];
  newAtchs?: File[];
  atchKeysToBeRmvd?: number[];
}

export class Base64UploadAdapter {
  public loader: any;
  public reader: any;
  constructor(loader: any) {
    // The file loader instance to use during the upload.
    this.loader = loader;
    this.reader = new window.FileReader();
  }

  // Starts the upload process.
  upload() {
    return new Promise((resolve, reject) => {
      const reader = this.reader;

      reader.addEventListener('load', () => {
        resolve({ default: reader.result });
      });

      reader.addEventListener('error', (err: Error) => {
        reject(err);
      });

      reader.addEventListener('abort', () => {
        reject();
      });

      this.loader.file.then((file: any) => {
        reader.readAsDataURL(file);
      });
    });
  }

  // Aborts the upload process.
  abort() {
    this.reader.abort();
  }
}
