<ng-container *ngIf="layoutService?.layoutConfig?.fullScreen; else nonFullScreen">
  <router-outlet></router-outlet>
</ng-container>
<ng-template #nonFullScreen>
  <div class="flex-normal flex-direction-column full-height">
    <div class="flex-auto non-login-content-window">
      <router-outlet></router-outlet>
    </div>
    <div class="footer ap-grid flex-normal flex-justify-center margin-top-auto">
      <app-footer class="g-col-12"></app-footer>
    </div>
  </div>
</ng-template>
<app-encapsulated-banner></app-encapsulated-banner>
<app-spinner *ngIf="emitService.loadingAPICount > 0"></app-spinner>