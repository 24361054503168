<div class="upload-files">
  <ap-fileupload uploadTitle="{{uploadTitle}}" [styleClass]="hiddenTitle ? 'hide-upload-file-title' : ''"
    [config]="config" [uploadInstruction]="uploadInstruction" [fileType]="fileType" [customUploader]="customUploader"
    (onAddFile)="onAddFile($event)" (onDeleteFile)="onDeleteFile($event)" (onValidatedFail)="onValidatedFail($event)">
  </ap-fileupload>
  <div *ngIf="singleFailList.length > 0 && !isFileTypeNotAllowed"
    class="ap-field-validation-error ap-typography-body-xs">
    {{singleFailList.length}} {{singleFailList.length > 1? 'files' : 'file'}} cannot be uploaded due to the size exceeds
    10MB.
  </div>
  <div *ngIf="totalFailList.length > 0 && !isFileTypeNotAllowed"
    class="ap-field-validation-error ap-typography-body-xs">
    {{totalFailList.length}} {{totalFailList.length > 1? 'files' : 'file'}} cannot be uploaded due to the total size
    exceeds 10MB.
  </div>
  <div *ngIf="isTotalFileSizeOverSize" class="ap-field-validation-error ap-typography-body-xs">Total file size exceeds
    10 MB.</div>
  <div *ngIf="isTotalFileLimitOver" class="ap-field-validation-error ap-typography-body-xs">The total file number can’t
    exceed more than {{ customUploader.maxFileLimit }}.</div>
  <div *ngIf="isFileTypeNotAllowed" class="ap-field-validation-error ap-typography-body-xs">File type is not allowed.
  </div>
  <div class="file-list" *ngIf="!hideFiles && filesData?.fileList?.length > 0">
    <div *ngFor="let file of filesData?.fileList"
      class="flex-normal align-items-center flex-justify-between file-list-container">
      <span class="Appkit4-icon icon-document-text-outline"></span>
      <span class="file-name text-ellipsis" ap-tooltip [tooltipId]="'attachmentsList'" ariaDescribedby="attachmentsList"
        [direction]="'top'" [tooltipContent]="file?.name || file?.fileName" [tooltipStyleClass]="'tool-tip-style'"
        role="tooltip">
        {{file?.name || file?.fileName}}
      </span>
      <button role="button" [attr.aria-label]="'Delete ' + (file?.fileName || file?.name)" (click)="onDeleteFile(file)">
        <span class="Appkit4-icon icon-circle-delete-outline"></span>
      </button>
    </div>
  </div>
  <button *ngIf="!hideFiles && filesData?.fileList.length > 1" role="button" aria-label="Delete all attachments"
    (click)="deleteAll()" class="file-list-delete-all ap-font-weight-2">
    Remove all attachments
  </button>
</div>
