<div class="feedback-panel" appClickOutside (clickOutside)="onClickedOutside($event)"
  [clickOutsideEnabled]="clickOutsideEnabled">
  <ap-drawer [visible]="emitService.showFeedbackPanel$ | async" [placement]="'right'" [mask]="false"
    [initialFocusIndex]="0" [drawerTitle]="'Send Feedback'" (onClose)="closeFeedbackPanel()">
    <div class="read-split ap-mb-spacing-5"></div>
    <div *ngIf="!feedbackSent">
      <ng-container *ngFor="let item of list">
        <ap-radio name="type" [value]="item.key" [(ngModel)]="groupRadioSelect">
          <span>{{ item.name }}</span>
        </ap-radio>
      </ng-container>
      <h2 class="ap-typography-heading-s ap-mt-spacing-5">
        Description
      </h2>
      <div class="rich-text ap-mt-spacing-5">
        <ap-text-editor [(data)]="textBody" [config]="docTextEditorConfig">
        </ap-text-editor>
      </div>
      <div class="ap-mt-spacing-5">
        <app-upload-file [filesData]="filesData" [uploadTitle]="'Files'"
          [fileType]="['XLS', 'XLSX', 'CSV', 'PDF', 'ZIP', 'JPG', 'PNG']" [customUploader]="customUploader"
          [uploadInstruction]="'Acceptable formats: XLS, XLSX, CSV, PDF, ZIP, JPG or PNG. The max file size is 10MB. 5 items upload limit.'"
          (filesDataChange)="filesDataChange($event)">
        </app-upload-file>
      </div>
      <div class="flex-normal flex-justify-end align-items-center ap-mt-spacing-7">
        <ap-button [btnType]="'primary'" [disabled]="!groupRadioSelect || !textBody" (click)="sendFeedback()"
          [label]="'Send'">
        </ap-button>
      </div>
    </div>
    <div *ngIf="feedbackSent"
      class="feedback-sent flex-normal align-items-center flex-direction-column flex-justify-center ap-mt-spacing-5">
      <img src="/assets/images/Success.svg" alt="empty state" />
      <h2 class="ap-typography-heading-m ap-mt-spacing-5">Thank you! Feedback sent!</h2>
    </div>
  </ap-drawer>
</div>