<div class="info-modal-conatiner" [ngClass]="styleClass" role="dialog" aria-modal="true" [attr.aria-label]="title">
  <div class="ap-modal-header-icons-container">
    <div tabindex="0"></div>
    <button *ngIf="!hideCross" type="button" aria-label="Close" (click)="cancel.emit()"
      class="ap-modal-header-icon ap-modal-header-close">
      <span class="Appkit4-icon icon-close-outline"></span>
    </button>
  </div>
  <div class="ap-modal-header">
    <ap-badge *ngIf="hasBadge" value="Warning" type="warning" size="small"></ap-badge>
    <h2 class="ap-modal-title">{{ title }}</h2>
  </div>
  <div class="ap-modal-body">
    <ng-content></ng-content>
  </div>
  <div *ngIf="!hideFooter" class="ap-modal-footer">
    <div class="ap-modal-footer-customize">
      <ap-button *ngIf="!hideCancel" [btnType]="'secondary'" [label]="cancelText" (onClick)="cancel.emit()"></ap-button>
      <ap-button [btnType]="btnType" (onClick)="save.emit()" (keyup.enter)="save.emit()" [label]="saveText"></ap-button>
    </div>
  </div>
</div>