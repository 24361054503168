import { Component, OnInit } from '@angular/core';
import { EmitService, FeedbackService, UtilService } from '@core';
import { Subscription } from 'rxjs';
import { DecodeStringPipe } from '../../pipe/decode-string/decode-string.pipe';
import { CommonModule } from '@angular/common';
import { ClickOutsideDirective } from '@shared/directive/click-outside/click-outside.directive';
import { DrawerModule } from '@appkit4/angular-components/drawer';
import { ButtonModule } from '@appkit4/angular-components/button';
import { RadioModule } from '@appkit4/angular-components/radio';
import { FormsModule } from '@angular/forms';
import { LoadingModule } from '@appkit4/angular-components/loading';
import { UploadFileTransfer } from '@shared/component/upload-file/upload-file.component';
import { UploadFileComponent } from '@shared/component/upload-file-gen2/upload-file.component';
import { TextEditorModule } from '@appkit4/angular-text-editor/text-editor';

@Component({
  selector: 'app-feedback',
  templateUrl: './feedback.component.html',
  styleUrls: ['./feedback.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    DrawerModule,
    ButtonModule,
    RadioModule,
    FormsModule,
    LoadingModule,
    ClickOutsideDirective,
    DecodeStringPipe,
    UploadFileComponent,
    TextEditorModule,
  ],
})
export class FeedbackComponent implements OnInit {
  list = [
    { name: 'Give a compliment', key: 'COMPLIMENT' },
    { name: 'Make a suggestion', key: 'SUGGESTION' },
    { name: 'Report a problem', key: 'PROBLEM' },
  ];
  customUploader = {
    maxFileSize: 10 * 1024 * 1024,
    autoUpload: false,
    maxFileLimit: 5,
  };
  textBody = '';
  docTextEditorConfig = {
    toolbar: [
      'bold',
      'italic',
      'underline',
      'bulletedList',
      'numberedList',
      'link',
    ],
  };
  groupRadioSelect: string;
  filesData: UploadFileTransfer = { fileList: [] };
  clickOutsideEnabled = false;
  feedbackSent = false;
  private readonly subs: Subscription[] = [];

  constructor(
    public readonly emitService: EmitService,
    private readonly feedbackService: FeedbackService,
    private readonly utilService: UtilService,
  ) {}

  ngOnInit(): void {
    this.subs.push(
      this.emitService.showFeedbackPanel$.subscribe((isOpened) => {
        if (isOpened) {
          setTimeout(() => {
            this.clickOutsideEnabled = true;
          }, 1000);
        } else {
          this.clickOutsideEnabled = false;
        }
      }),
    );
  }

  filesDataChange(event: UploadFileTransfer) {
    this.filesData = { ...event };
  }

  clearData() {
    this.textBody = '';
    this.groupRadioSelect = '';
    this.filesData = { fileList: [] };
    this.feedbackSent = false;
    this.emitService.showFeedbackPanel$.next(false);
  }

  closeFeedbackPanel() {
    if (
      this.textBody ||
      this.groupRadioSelect ||
      this.filesData.fileList.length
    ) {
      this.utilService.unsavedChangeAlert('Cancel send Feedback').subscribe((isContinue) => {
        if (isContinue) {
          this.clearData();
        }
      });
    } else {
      this.clearData();
    }
  }

  onClickedOutside(e: any) {
    if (
      !e.srcElement?.classList?.contains('panel-target') &&
      !e.srcElement?.classList?.contains('icon-circle-delete-outline') &&
      !e.srcElement?.classList?.contains('file-list-delete-all')
    ) {
      this.closeFeedbackPanel();
    }
  }

  sendFeedback() {
    this.clickOutsideEnabled = false;
    const variables = {
      feedbackType: this.groupRadioSelect as any,
      content: this.textBody,
      attachments: this.filesData.fileList as any,
    };
    this.emitService.startLoading();
    const sub = this.feedbackService
      .saveFeedback(variables)
      .subscribe((res) => {
        this.emitService.endLoading();
        if (res.status === 'SUCCESS') {
          this.utilService.addToastMessage('Feedback sent');
          this.feedbackSent = true;
          this.textBody = '';
          this.groupRadioSelect = '';
          this.filesData = { fileList: [] };
        } else {
          this.utilService.addBannerMessage('Feedback could not be sent');
        }
      });
    this.subs.push(sub);
    setTimeout(() => {
      this.clickOutsideEnabled = true;
    }, 1000);
  }
}
