import { AccountTag } from '@core/gql/chart-of-accounts.gql';
import { Tenant } from '@models';

export interface SideNav {
  icon: string;
  value: string;
  routerLink: string;
  permission?: string;
  clearCustomersState?: boolean;
  clearCustomerGroupsState?: boolean;
}

export interface DetailText {
  title?: string;
  subtitle?: string[];
  tipTitle?: string;
  detailTips?: string[];
  position?: string; // for popup
}

export interface TipPosition {
  left?: string;
  right?: string;
  transform: string;
}

export interface MonthlyChange {
  value?: string;
  trend?: 'up' | 'down';
  reverse?: boolean;
}

export interface Content extends DetailText {
  value?: string;
  monthlyChange?: MonthlyChange;
  statistics?: { [key: string]: string };
}

export type CardStates = 'loading' | 'empty';

export interface CardBase extends DetailText {
  states?: CardStates;
}

export type Card = SummaryCard | ChartCard | TableCard | PieSummaryCard;

export interface SummaryCard extends CardBase {
  type?: 'summary';
  contents?: Content[];
  chart?: Chart &
    DetailText & {
      valueShortFormatter?: (value: number) => string;
    };
  routePath?: string;
}

export interface PieSummaryCard extends CardBase {
  type?: 'pieSummary';
  chart?: PieChart;
}

export interface TableCard<T = any> extends CardBase {
  type?: 'table';
  table?: Table<T>;
}

export interface ChartCard extends CardBase {
  type?: 'combo-chart' | 'combo-horizontal-chart';
  chart?: Chart;
}

export interface Chart {
  lineChart?: Data[];
  barChart?: Data[];
  legends?: Legend[];
  groupType?: 'standard' | 'stacked' | 'normalized';
  valueType?: 'number' | 'currency' | 'percent' | 'hour';
  viewNum?: number;
  yAxisLabel?: string;
  yRightAxis?: boolean;
  yRightAxisLabel?: string;
  valueFormatter?: (value: number) => string;
  hideLegend?: boolean;
}

export interface PieChart {
  total: SummaryData;
  pieChart?: any;
  valueFormatter?: (value: number) => string;
}

export class SummaryData {
  name?: string;
  value?: number;
}

export interface Data {
  name: string;
  series: Series[];
  dash?: boolean;
}

export interface Series {
  name: string;
  value: number;
  parentCategory?: string;
  tooltips?: Tooltip[]; // for tooltip
}

export interface Legend {
  name: string;
  title?: string;
  type?: 'bar' | 'line';
  color?: string;
  dash?: boolean;
}

export interface Tooltip extends Legend {
  value: string;
}

export interface AddTenantObj {
  tenantName: string;
  email: string;
}

export interface ToastType {
  message?: string;
  themeColor?: string;
  statusIcon?: string;
  showClose?: boolean;
  showUndo?: boolean;
  showCancel?: boolean;
}

export interface SnackBarConfig {
  panelClass?: string | string[];
  duration?: number;
  data?: ToastType;
  isDelay?: boolean;
}

export interface BannerType {
  bannerId: number;
  message?: string;
  bannerStatus?: string;
}

export interface Table<T = any> {
  data?: T[];
  columns?: DataTableColumn[];
  defaultSort?: DataTableSort;
  summaryRow?: boolean;
}

export interface DataTableColumn {
  key?: string;
  title?: string;
  sortable?: boolean;
  width?: number;
  flex?: number;
  summary?: string;
  render?: (value?: any, row?: any, column?: DataTableColumn) => any;
  // pipe?: any; // TODO: implement later
  // transform?: (value?: any, row?: any, column?: DataTableColumn) => any; // TODO: implement later
  // comparator?: (valueA?: any, valueB?: any, rowA?: any, rowB?: any, sortDirection?: any) => -1 | 0 | 1; // TODO: implement later
}

export interface DataTableSort {
  key?: string;
  name?: string;
  dir?: 'asc' | 'desc';
}

export interface MobileSortData {
  sortKey?: string;
  sortDir?: 'asc' | 'desc';
  sorts?: DataTableSort[];
  resetName?: boolean;
}

export interface MobileFilterData<T = any> {
  type?: 'filter' | 'customerFilter';
  filter?: T;
  counts?: number[];
  customerFilter?: any;
  statusOrder?: string[];
}

export interface WrappedLoading<T> {
  data: T;
  loading: boolean;
}

export interface ChartsOfAccount {
  accountKey: number;
  accountNbr: string;
  nme: string;
  industry?: string;
  originalType?: BasicAccountType;
  modifiedType?: BasicAccountType;
  status?: AccountTypeStatus;
  customerName?: string;
  parentNbr?: string;
  parentNme?: string;
  typeDetails: string;
  accountTags: AccountTag;
  accountType?: string;
  lastSyncedAt?: Date;
}

export interface Class {
  classKey?: number;
  tenantKey?: number;
  name?: string;
  description?: string;
  createdAt?: Date;
}

export interface Vendor {
  vendorKey?: number;
  name?: string;
  displayName?: string;
}

export interface Expense {
  transactionDate: string;
  amount: string;
  bankName: string;
  bankFeedKey: number;
  description: string;
  memo?: string;
  selectCategory?: SelectCategory;
  selectClass?: Class;
  selectVendor?: Vendor;
  selectCustomerProject?: Vendor;
  originMemo?: string;
  originalSelectedCategory?: SelectCategory;
  isEdit: boolean;
  isSelected: boolean;
  categoryOrMatch: string;
  action?: string;
  isShowSlidePanel?: boolean;
  bankDetail?: string;
  hashBankDetail?: string;
}

export interface SelectCategory {
  accountKey: number;
  nme: string;
  accountNbr?: string;
}

export enum NotificationDialogType {
  UNSAVED_CHANGES = 'leave',
  EXISTS_RULE = 'exists',
}

export enum ActionsType {
  MATCH = 'Match',
  VIEW = 'View',
}

export interface CustomerOption {
  tenant: Tenant;
  pendingAction?: string;
  applied?: boolean;
}

export interface PayeeGroup {
  vendorKey: number;
  name: string;
  displayName: string;
  type: VendorType | string;
  parentRec?: PayeeGroup;
}

export enum VendorType {
  Customer = 'CUSTOMER',
  Vendor = 'VENDOR',
  Project = 'PROJECT',
}

export interface UserActionQueryInput {
  tenantKey?: number;
  tenantName?: string;
  actionType?: UserActionType;
  userKey?: number;
  startDate?: Date | string;
  endDate?: Date | string;
  orderBy?: string;
  sortingType?: SortingType;
  limit?: number;
  offset?: number;
}

export enum UserActionType {
  ALL = 'ALL',
  CUSTOMER_CREATE = 'CUSTOMER_CREATE',
  CUSTOMER_ONBOARD = 'CUSTOMER_ONBOARD',
  CUSTOMER_ACTIVATE = 'CUSTOMER_ACTIVATE',
  CUSTOMER_DEACTIVATE = 'CUSTOMER_DEACTIVATE',
  USER_INVITE = 'USER_INVITE',
  USER_INVITE_RESEND = 'USER_INVITE_RESEND',
  USER_ACTIVATE = 'USER_ACTIVATE',
  USER_DEACTIVATE = 'USER_DEACTIVATE',
  ACCOUNT_CONNECT = 'ACCOUNT_CONNECT',
  DASHBOARD_GENERATE = 'DASHBOARD_GENERATE',
  DASHBOARD_DENY = 'DASHBOARD_DENY',
  DASHBOARD_APPROVE = 'DASHBOARD_APPROVE',
  USER_UPDATE = 'USER_UPDATE',
  USER_DELETE = 'USER_DELETE',
  USER_REGISTER = 'USER_REGISTER',
  USER_SUBMIT_FORM = 'USER_SUBMIT_FORM',
  USER_SUBMIT_BANK_FEED = 'USER_SUBMIT_BANK_FEED',
  USER_SUBMIT_TRANSACTION_SPLIT = 'USER_SUBMIT_TRANSACTION_SPLIT',
  USER_SEND_NOTIFICATION = 'USER_SEND_NOTIFICATION',
  NOTIFICATION_SENT = 'NOTIFICATION_SENT',
  USER_ADD_VENDOR = 'USER_ADD_VENDOR',
  USER_LOGGED_IN = 'USER_LOGGED_IN',
  USER_LOGGED_OUT = 'USER_LOGGED_OUT',
  GROUP_CREATED = 'GROUP_CREATED',
  GROUP_UPDATED = 'GROUP_UPDATED',
  GROUP_DELETED = 'GROUP_DELETED',
  UNMATCHED_TRANSACTIONS_SENT = 'UNMATCHED_TRANSACTIONS_SENT',
  USER_INVITED = 'USER_INVITED',
  USER_CHANGE_ROLE = 'USER_CHANGE_ROLE',
  QUICK_LINK_ADDED = 'QUICK_LINK_ADDED',
  QUICK_LINK_UPDATED = 'QUICK_LINK_UPDATED',
  QUICK_LINK_DELETED = 'QUICK_LINK_DELETED',
  CUSTOMER_GROUP_UPDATED = 'CUSTOMER_GROUP_UPDATED',
  REPORT_UPLOADED = 'REPORT_UPLOADED',
  REPORT_DOWNLOADED = 'REPORT_DOWNLOADED',
  REPORT_DELETED = 'REPORT_DELETED',
  REPORT_NOTIFICATION_SENT = 'REPORT_NOTIFICATION_SENT',
  COMMENT_NOTIFICATION_SENT = 'COMMENT_NOTIFICATION_SENT',
  NOTE_NOTIFICATION_SENT = 'NOTE_NOTIFICATION_SENT',
  REPORT_EDITED = 'REPORT_EDITED',
  CUSTOMER_REPORT_EDITED = 'CUSTOMER_REPORT_EDITED',
  TAG_MAPPING_ADDED = 'TAG_MAPPING_ADDED',
  TAG_MAPPING_REMOVED = 'TAG_MAPPING_REMOVED',
  TAG_CREATED = 'TAG_CREATED',
  ACCOUNT_TYPE_MODIFIED = 'ACCOUNT_TYPE_MODIFIED',
  ACCOUNT_TYPE_CONFIRMED = 'ACCOUNT_TYPE_CONFIRMED',
  GENERAL_LEDGER_CONNECTED = 'GENERAL_LEDGER_CONNECTED',
  CUSTOMER_USER_INVITED = 'CUSTOMER_USER_INVITED',
  CUSTOMER_USER_INVITE_RESEND = 'CUSTOMER_USER_INVITE_RESEND',
  CUSTOMER_USER_DELETE = 'CUSTOMER_USER_DELETE',
  BOOKKEEPER_ASSIGNMENT_ADDED = 'BOOKKEEPER_ASSIGNMENT_ADDED',
  BOOKKEEPER_ASSIGNMENT_DELETE = 'BOOKKEEPER_ASSIGNMENT_DELETE',
  QUICK_LINK_ADDED_INDIVIDUAL = 'QUICK_LINK_ADDED_INDIVIDUAL',
  QUICK_LINK_UPDATED_INDIVIDUAL = 'QUICK_LINK_UPDATED_INDIVIDUAL',
  QUICK_LINK_DELETED_INDIVIDUAL = 'QUICK_LINK_DELETED_INDIVIDUAL',
  CUSTOMER_BK_ASSIGNMENT_ADDED = 'CUSTOMER_BK_ASSIGNMENT_ADDED',
  CUSTOMER_BK_ASSIGNMENT_DELETE = 'CUSTOMER_BK_ASSIGNMENT_DELETE',
  CUSTOMER_USER_CHANGE_ROLE = 'CUSTOMER_USER_CHANGE_ROLE',
  PRIMARY_CUSTOMER_USER_INVITED = 'PRIMARY_CUSTOMER_USER_INVITED',
  COMPANY_ADDED = 'COMPANY_ADDED',
  SUBENTITY_ADDED = 'SUBENTITY_ADDED',
  CUSTOMER_DETAILS_UPDATED = 'CUSTOMER_DETAILS_UPDATED',
  TEMPLATE_LIB_CREATED = 'TEMPLATE_LIB_CREATED',
  TEMPLATE_LIB_DELETED = 'TEMPLATE_LIB_DELETED',
  TASK_LIB_CREATED = 'TASK_LIB_CREATED',
  TASK_LIB_EDITED = 'TASK_LIB_EDITED',
  TASK_LIB_DELETED = 'TASK_LIB_DELETED',
  ATTACHMENTS_DOWNLOADED = 'ATTACHMENTS_DOWNLOADED',
  TASK_NOTE_ADDED = 'TASK_NOTE_ADDED',
  TASK_COMMENT_ADDED = 'TASK_COMMENT_ADDED',
  TASK_PROG_CREATED = 'TASK_PROG_CREATED',
  TASK_PUBLISHED = 'TASK_PUBLISHED',
  TASK_ASSIGNED = 'TASK_ASSIGNED',
  REVIEWER_ASSIGNED = 'REVIEWER_ASSIGNED',
  TASK_CLOSED = 'TASK_CLOSED',
  TASK_ON_HOLD = 'TASK_ON_HOLD',
  TASK_IN_PROGRESS = 'TASK_IN_PROGRESS',
  TASK_WAITING_ON_CLIENT = 'TASK_WAITING_ON_CLIENT',
  TASK_READY_FOR_REVIEW = 'TASK_READY_FOR_REVIEW',
  TASK_NOT_STARTED = 'TASK_NOT_STARTED',
  TASK_CREATED = 'TASK_CREATED',
  TASK_UPDATED = 'TASK_UPDATED',
  TASK_TEMPLATE_ASSIGN_ADD = 'TASK_TEMPLATE_ASSIGN_ADD',
  TASK_MARKED_SKIP_APVL = 'TASK_MARKED_SKIP_APVL',
  TASK_TEMPLATE_ASSIGN_REMOVE = 'TASK_TEMPLATE_ASSIGN_REMOVE',
  USER_TENANT_ASSIGN_ADD = 'USER_TENANT_ASSIGN_ADD',
  USER_TENANT_ASSIGN_REMOVE = 'USER_TENANT_ASSIGN_REMOVE',
  TEAM_LEAD_REASSIGN = 'TEAM_LEAD_REASSIGN',
  REVIEWER_REASSIGN = 'REVIEWER_REASSIGN',
  REASSIGN_TASK_ASSIGNEE = 'REASSIGN_TASK_ASSIGNEE',
  REASSIGN_TASK_REVIEWER = 'REASSIGN_TASK_REVIEWER',
  UPDATE_FEATURE_VISIBILITY = 'UPDATE_FEATURE_VISIBILITY',
  CUSTOMER_INVITED = 'CUSTOMER_INVITED',
  CUSTOMER_INVITE_RESENT = 'CUSTOMER_INVITE_RESENT',
  CUSTOMER_DELETED = 'CUSTOMER_DELETED',
  CUSTOMER_COMMENT_NOTIFICATION_SENT = 'CUSTOMER_COMMENT_NOTIFICATION_SENT',
  CUSTOMER_MARK_TASK_COMPLETED = 'CUSTOMER_MARK_TASK_COMPLETED',
  CUSTOMER_CHANGE_ROLE = 'CUSTOMER_CHANGE_ROLE',
  TASK_VISIBILITY_UPDATED = 'TASK_VISIBILITY_UPDATED',
  FEEDBACK_SAVED = 'FEEDBACK_SAVED',
  CUSTOMER_USER_REACTIVATE = 'CUSTOMER_USER_REACTIVATE',
  CUSTOMER_USER_DEACTIVATE = 'CUSTOMER_USER_DEACTIVATE'
}

export enum ActionSubType {
  GROUP_SELF_UPDATE = 'GROUP_SELF_UPDATE',
  CUSTOMER_ADDED = 'CUSTOMER_ADDED',
  CUSTOMER_DELETED = 'CUSTOMER_DELETED',
  GROUP_ADDED = 'GROUP_ADDED',
  GROUP_DELETED = 'GROUP_DELETED',
}

export enum SortingType {
  ASC = 'ASC',
  DESC = 'DESC',
}

export enum BasicAccountType {
  Asset = 'Asset',
  Liability = 'Liability',
  Revenue = 'Revenue',
  Expense = 'Expense',
  Equity = 'Equity',
}

export enum AccountTypeStatus {
  CONFIRMED = 'CONFIRMED',
  DENIED = 'DENIED',
}

export enum GroupRuleAction {
  ADD = 'ADD',
  DELETE = 'DELETE',
}

export enum ActiveStatus {
  ACTIVE = 'ACTIVE',
  DEACTIVATED = 'DEACTIVATED',
}

export interface GroupCustomer {
  tenantKey: number;
  action: GroupRuleAction;
}

export interface GroupInput {
  groupKey?: number;
  name?: string;
  description?: string;
  customers?: [GroupCustomer];
}

export interface GroupResponse {
  status: string;
  message: string;
}
