import { Injectable } from '@angular/core';
import { GqlService } from '@core';
import {
  FeedbackInput,
  getFeedbackAttachment,
  listFeedback,
  ListFeedbackInput,
  listFeedbackOwners,
  saveFeedback,
} from '@core/gql/feedback.gql';

@Injectable({
  providedIn: 'root',
})
export class FeedbackService {
  constructor(private gqlService: GqlService) {}

  saveFeedback(input: FeedbackInput) {
    return this.gqlService.mutate(
      saveFeedback,
      { input },
      { useMultipart: true },
    );
  }

  listFeedbackOwners() {
    return this.gqlService.query(listFeedbackOwners);
  }

  listFeedback(input: ListFeedbackInput) {
    return this.gqlService.query(listFeedback, {
      input,
    });
  }

  getFeedbackAttachment(feedbackAttachmentKey: number) {
    return this.gqlService.query(getFeedbackAttachment, {
      feedbackAttachmentKey,
    });
  }
}
