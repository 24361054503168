import gql from 'graphql-tag';
import { tenantFragment } from './base.gql';
import { SortingType, ActiveStatus } from '@core/types';

export const validateAccessToken = gql`
  query validateAccessToken($accessTokenInput: AccessTokenInput) {
    validateAccessToken(input: $accessTokenInput) {
      status
      accessToken
    }
  }
`;

export const grantAccessToken = gql`
  mutation grantAccessToken($input: GrantAccessTokenInput) {
    grantAccessToken(input: $input) {
      accessToken
      refreshToken
      expiresIn
      idToken
    }
  }
`;

export const refreshAccessToken = gql`
  mutation refreshAccessToken($input: RefreshAccessTokenInput) {
    refreshAccessToken(input: $input) {
      accessToken
      expiresIn
      idToken
    }
  }
`;

export const renewUserSession = gql`
  mutation renewUserSession {
    renewUserSession {
      message
      status
      expires
    }
  }
`;

export const automationLogin = gql`
  mutation AutomationLogin($input: AutomationLoginInput!) {
    automationLogin(input: $input) {
      message
      status
      authUser {
        email
        userTypeKey
        name
      }
      sessionID
      expires
    }
  }
`;

export const userLogin = gql`
  mutation userLogin($input: UserLoginInput) {
    userLogin(input: $input) {
      message
      status
      authUser {
        email
        userTypeKey
        name
      }
      sessionID
      expires
    }
  }
`;

export const userLogout = gql`
  mutation userLogout {
    userLogout {
      message
      status
      authUser {
        logoutHint
      }
    }
  }
`;

export const fetchUser = gql`
  query fetchUser($fetchUserInput: FetchUserInput) {
    fetchUser(input: $fetchUserInput) {
      message
      status
      user {
        firstName
        lastName
      }
    }
  }
`;

export const fetchUserInfo = gql`
  query fetchUserInfo($fetchUserInfoInput: FetchUserInfoInput) {
    fetchUserInfo(input: $fetchUserInfoInput) {
      message
      status
      data {
        email
        firstNme
        lastNme
        userStatus {
          nme
          userStatusKey
        }
      }
    }
  }
`;

export const registerUser = gql`
  mutation registerUser($registerUserInput: RegisterUserInput) {
    registerUser(input: $registerUserInput) {
      message
      status
    }
  }
`;

export const listUsers = gql`
  query listUsers(
    $listUsersInput: UserListInput
    $skipAssignedTenants: Boolean = false
  ) {
    listUsers(input: $listUsersInput) {
      message
      status
      count
      data {
        userKey
        email
        phone
        bio
        firstNme
        lastNme
        originalPriTenant {
          tenantKey
          nme
        }
        appointmentLink
        userStatus {
          nme
        }
        userType {
          nme
        }
        businessFunction {
          businessFunctionKey
          name
        }
        updatedAt
        isCurrent
        avatar
        specialRole
        iamUserType
        assignedTenants @skip(if: $skipAssignedTenants) {
          tenant {
            nme
          }
          userType {
            nme
          }
          businessFunction {
            name
          }
        }
      }
    }
  }
`;

export const listAssociatedUsers = gql`
  query listAssociatedUsers(
    $listUsersInput: UserListInput
    $skipAssignedTenants: Boolean = false
  ) {
    listAssociatedUsers(input: $listUsersInput) {
      message
      status
      count
      activeCount
      deactivatedCount
      data {
        userKey
        email
        phone
        bio
        firstNme
        lastNme
        appointmentLink
        userStatus {
          nme
        }
        userType {
          nme
        }
        businessFunction {
          businessFunctionKey
          name
        }
        updatedAt
        isCurrent
        tenant {
          tenantKey
          nme
        }
        originalPriTenant {
          tenantKey
          nme
        }
        avatar
        iamUserType
        assignedTenants @skip(if: $skipAssignedTenants) {
          tenant {
            nme
          }
          userType {
            nme
          }
          businessFunction {
            name
          }
        }
        isDeletable
        invitedAt
      }
    }
  }
`;

export const listDeactivatedUsers = gql`
  query listAssociatedUsers($listUsersInput: UserListInput) {
    listAssociatedUsers(input: $listUsersInput) {
      message
      status
      count
      activeCount
      deactivatedCount
      data {
        userKey
        email
        firstNme
        lastNme
        avatar
        updatedAt
        phone
        bio
        appointmentLink
      }
    }
  }
`;

export const deactivateUser = gql`
  mutation deactivateUser($userKey: Int!) {
    deactivateUser(userKey: $userKey) {
      message
      status
    }
  }
`;

export const reactivateUser = gql`
  mutation reactivateUser($userKey: Int!) {
    reactivateUser(userKey: $userKey) {
      message
      status
    }
  }
`;

export const inviteUser = gql`
  mutation inviteUser($inviteUserInput: InviteUserInput) {
    inviteUser(input: $inviteUserInput) {
      message
      status
      user {
        email
      }
    }
  }
`;

export const changeUserRole = gql`
  mutation changeUserRole($changeUserRoleInput: ChangeUserRoleInput) {
    changeUserRole(input: $changeUserRoleInput) {
      message
      status
    }
  }
`;

export const deleteUser = gql`
  mutation deleteUser($input: DeleteUserInput) {
    deleteUser(input: $input) {
      message
      status
      user {
        email
      }
    }
  }
`;

export const getUser = gql`
  ${tenantFragment}
  query getUser($tenantKey: Int) {
    getUser(tenantKey: $tenantKey) {
      userKey
      email
      firstNme
      lastNme
      phone
      countryCode
      tenant {
        ...tenantFragment
        nextOrgGroupId
        orgStatus
      }
      employee {
        employeeType {
          employeeTypeKey
          nme
        }
      }
      userType {
        nme
      }
      permissions {
        nme
        displayNme
      }
      bio
      avatar
      appointmentLink
      theme
      iamUserType
    }
  }
`;

export const updateUser = gql`
  mutation updateUser($updateUserInput: UpdateUserInfoInput!) {
    updateUserInfo(input: $updateUserInput) {
      message
      status
    }
  }
`;

export const sendCode = gql`
  mutation sendCode($input: SendPhoneResetCodeInput) {
    sendPhoneResetCode(input: $input) {
      message
      status
      sid
    }
  }
`;

export const validateCode = gql`
  mutation validateCode($input: ValidatePhoneResetCodeInput) {
    validatePhoneResetCode(input: $input) {
      message
      status
    }
  }
`;

export const deleteAvatar = gql`
  mutation deleteAvatar {
    deleteAvatar {
      message
      status
    }
  }
`;

export const assignTenantsForBK = gql`
  mutation assignTenantsForBK($input: AssignCustomersInput) {
    assignCustomers(input: $input) {
      message
      status
    }
  }
`;

export const assignTenantsForUser = gql`
  mutation assignTenantsForBK($input: AssignUserToTenantsInput) {
    assignUserToTenants(input: $input) {
      message
      status
    }
  }
`;

export const unassignTenantsForUser = gql`
  mutation unassignTenantsForUser($userKey: Int!, $tenantKeys: [Int!]!) {
    removeUserFromTenants(userKey: $userKey, tenantKeys: $tenantKeys) {
      message
      status
    }
  }
`;

export const getUserInfo = gql`
  query getUserInfo($userKey: Int!, $tenantKey: Int) {
    getUserInfo(userKey: $userKey, tenantKey: $tenantKey) {
      userKey
      email
      phone
      firstNme
      lastNme
      avatar
      userType {
        nme
      }
      userStatus {
        nme
      }
    }
  }
`;

export const getAssignedTenantsForUser = gql`
  query getTenantsForUser($input: GetTenantsForUserInput) {
    getTenantsForUser(input: $input) {
      count
      data {
        tenant {
          nme
          tenantKey
        }
        userType {
          nme
        }
        businessFunction {
          name
        }
        isCurrent
        updatedAt
      }
    }
  }
`;

export const getUnassignedTenantsForUser = gql`
  query listUnassignedTenants($userKey: Int) {
    listUnassignedTenants(userKey: $userKey) {
      data {
        tenantKey
        nme
        industry {
          industryKey
        }
      }
    }
  }
`;

export const userDuplicateCheck = gql`
  query userDuplicateCheck($input: UserDuplicateCheckInput) {
    userDuplicateCheck(input: $input) {
      message
      status
    }
  }
`;

export const listAssignedTenants = gql`
  query listAssignedTenants {
    listAssignedTenants {
      message
      status
      data {
        tenantKey
        nme
        orgUserGroupId
        orgAdminGroupId
      }
    }
  }
`;

export const getTenantsForLeaderRole = gql`
  query getTenantsForLeaderRole($userKey: Int) {
    getTenantsForLeaderRole(targetUserKey: $userKey) {
      message
      status
      data {
        tenantsForTeamLead {
          tenantKey
          nme
        }
        tenantsForReviewer {
          tenantKey
          nme
        }
      }
    }
  }
`;

export const getTenantsForSpecialRole = gql`
  query getTenantsForSpecialRole($input: TenantsForSpecialRoleInput) {
    getTenantsForSpecialRole(input: $input) {
      message
      status
      count
      data {
        tenantKey
        nme
        teamRoleType
      }
    }
  }
`;

export const bulkReassignLeaderRole = gql`
  mutation bulkReassignLeaderRole($input: ReassignLeaderRoleInput!) {
    bulkReassignLeaderRole(input: $input) {
      message
      status
    }
  }
`;

export const reassignAndDeleteUser = gql`
  mutation reassignAndDeleteUser($input: ReassignAndDeleteUserInput!) {
    reassignAndDeleteUser(input: $input) {
      message
      status
    }
  }
`;

export const reassignAndDeleteCustomer = gql`
  mutation reassignAndDeleteCustomer($input: ReassignAndDeleteCustomerInput!) {
    reassignAndDeleteCustomer(input: $input) {
      message
      status
    }
  }
`;

export const getEmailPreferenceTypes = gql`
  query getEmailPreferenceTypes {
    listEmailPreferenceType {
      message
      status
      data {
        emailPreferenceTypeKey
        displayName
      }
    }
  }
`;

export const getEmailPreferences = gql`
  query getEmailPreferences($input: ListEmailPreferenceInput) {
    listEmailPreference(input: $input) {
      message
      status
      count
      user {
        userKey
        email
        firstNme
        lastNme
        weeklyDigest
        allNotification
      }
      data {
        tenant {
          tenantKey
          nme
        }
        preference
      }
    }
  }
`;

export const listPermissions = gql`
  query listPermissions {
    listPermissions {
      message
      status
      data {
        category
        permissionSet {
          displayName
          userTypes
        }
      }
    }
  }
`;

export const updateEmailPreferences = gql`
  mutation updateEmailPreferences($input: UpdateEmailPreferenceInput) {
    updateEmailPreference(input: $input) {
      message
      status
    }
  }
`;

export const getUserActions = gql`
  query getUserActions($input: UserActionQueryInput) {
    getUserActions(input: $input) {
      status
      message
      count
      data {
        actionType
        comment
        timestamp
        user {
          firstNme
          lastNme
          email
        }
      }
    }
  }
`;

export const getActionTypes = gql`
  query getActionTypes {
    listActionTypes {
      message
      status
      data {
        name
        description
      }
    }
  }
`;

export const listLoggedUsers = gql`
  query listLoggedUsers($tenantKeys: [Int], $isBk: Boolean) {
    listLoggedUsers(tenantKeys: $tenantKeys, isBk: $isBk) {
      message
      status
      data {
        userKey
        email
        firstNme
        lastNme
        userType {
          nme
        }
      }
    }
  }
`;

export const verifyDomain = gql`
  mutation verifyDomain($domain: VerifyDomainInput) {
    verifyDomain(input: $domain) {
      message
      status
      isValid
    }
  }
`;

export interface RegisterUserInput {
  activationId: string;
  activationToken: string;
  countryCode?: string;
  newPassword?: string;
  phoneNumber?: string;
}

export interface UpdateUserInput {
  email: string;
  mobileNumber?: string;
  countryCode?: string;
  bio?: string;
  avatar?: File;
  appointmentLink?: string;
}

export interface ValidateCodeInput {
  email: string;
  mobileNumber: string;
  countryCode: string;
  sid: string;
  otp: string;
}

export enum UserType {
  ADMIN = 'ADMIN',
  SUPER_ADMIN = 'SUPER_ADMIN',
  MEMBER = 'MEMBER',
  BOOKKEEPER_ADMIN = 'BOOKKEEPER_ADMIN',
  BOOKKEEPER = 'BOOKKEEPER',
  CONTRIBUTOR = 'CONTRIBUTOR',
}

export const CustomerUserTypes = [
  { label: 'Contributor', value: UserType.CONTRIBUTOR },
  { label: 'Member', value: UserType.MEMBER },
  { label: 'Admin', value: UserType.ADMIN },
];

export const BookkeeperUserTypes = [
  { label: 'Bookkeeper', value: UserType.BOOKKEEPER },
  { label: 'Bookkeeper Admin', value: UserType.BOOKKEEPER_ADMIN },
  { label: 'Super Admin', value: UserType.SUPER_ADMIN },
];

export const BusinessFunctions = [
  {
    label: 'Accounting Manager',
    value: 'Accounting Manager',
  },
  {
    label: 'Business Owner',
    value: 'Business Owner',
  },
  {
    label: 'CEO',
    value: 'CEO',
  },
  {
    label: 'CFO',
    value: 'CFO',
  },
  {
    label: 'Controller',
    value: 'Controller',
  },
  {
    label: 'Office Administrator',
    value: 'Office Administrator',
  },
  {
    label: 'Office Manager',
    value: 'Office Manager',
  },
  {
    label: 'Office Staff',
    value: 'Office Staff',
  },
  {
    label: 'Other',
    value: 'Other',
  },
];

export interface DuplicateCheckData {
  email: string;
  tenantKey: number;
}

export interface UserListInput {
  tenantKey?: number;
  searchText?: string;
  bkOpt?: BKOpt;
  orderBy?: string;
  sortingType?: SortingType;
  limit?: number;
  offset?: number;
  statusFilter?: ActiveStatus;
}

export interface BKOpt {
  includeSA: boolean;
  includeSelf: boolean;
  registered: boolean;
}

export interface UserDuplicateCheckInput {
  email: string;
  tenantKey: number;
}

export interface InviteUserType {
  email: string;
  role: UserType;
  businessFunction: string;
}

export interface InviteUserInput {
  users: InviteUserType[];
  requestingUserEmail?: string;
  accessToken?: string;
  emailLanguage?: string;
  state?: string;
  tenantKey?: number;
}

export interface ReassignAndDeleteCustomerInput {
  bulkDelete?: boolean;
  targetUserKey: number;
  tenantKey: number;
}

export enum REASSIGN_STEP {
  TEAM_LEAD = 'TEAM_LEAD',
  REVIEWER = 'REVIEWER',
}

export interface VerifyDomainInput {
  domain: string;
}
